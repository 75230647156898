<template>
  <div>
    <input type="file" ref="uploadPdf" @change="getFile($event)"  style="display: none" accept=".doc">
<!--      <el-select placeholder="请选择合同模板" v-model="scontractmodel" size="small">-->
<!--        <el-option :key="item.code" :label="item.code" :value="item.name" v-for="item in dictValList['CONTRATE_MODEL']"></el-option>-->
<!--      </el-select>-->
      <el-button @click="previewTemplate" type="primary" size="medium ">预览模板</el-button>
      <el-button @click="downloadTemplate" type="primary" size="medium ">下载模板</el-button>
    <el-button type="primary" @click="pdfUpload" size="medium ">上传模板</el-button>
  </div>
</template>

<script>
import { http } from 'security-view-deal'
export default {
  name: 'uploadTemplate',
  data() {
    return {
      contractFlag: false,
      scontractmodel: '',
      dictValList: {},
      dialogPdfVisible: false,
      fileName: '',
      uploadFlag: false,
      uploadMsg: '',
      file: null,
      src: '',
      currentPage: 2,
      pageSize: 5
    }
  },
  mounted() {
    // this.initDictList('CONTRATE_MODEL')
  },
  methods: {
    async initDictList(itemCodes) {
      var result = {}
      if (!itemCodes) {
        return result
      }
      var _this = this
      await http.get('/basic/dictValue/findDictValueByItemCodes', { itemCodes: itemCodes }).then(data => {
        if (data) {
          for (var i = 0; i < data.length; i++) {
            result[data[i].itemCode] = data[i].valueVoList
          }
        }
        _this.dictValList = result
      })
    },
    pdfUpload() {
      this.$refs.uploadPdf.dispatchEvent(new MouseEvent('click'))
      this.uploadFlag = false
      this.uploadMsg = ''
    },
    getFile(event) {
      var file = event.target.files
      this.file = null
      this.fileName = ''
      if (file.length === 1) {
        // 上传类型判断
        var imgName = file[0].name
        var idx = imgName.lastIndexOf('.')
        if (idx !== -1) {
          var ext = imgName.substr(idx + 1).toUpperCase()
          ext = ext.toLowerCase()
          if (ext !== 'doc') {
            return this.$message.info('仅支持上传doc文件')
          } else {
            this.fileName = imgName.substr(0, idx)
            var formData = new FormData()
            formData.append('file', file[0])
            http.post('/contract/contract/uploadTemplate', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
              this.uploadFlag = true
              this.$message.success('上传成功')
            })
          }
        } else {
          return this.$message.info('仅支持上传doc文件')
        }
      }
    },
    downloadTemplate() {
      if (!this.scontractmodel) {
        // return this.$message.warning('请选择模板')
      }
      const elink = document.createElement('a')
      elink.style.display = 'none'
      let token = window.sessionStorage.getItem('authorization')
      if (token) {
        token = token.replace('"', '')
      }
      elink.href = '/api/contract/contract/downloadTemplate?token=' + token + '&name=' + this.scontractmodel
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)
    },
    previewTemplate() {
      if (!this.scontractmodel) {
        // return this.$message.warning('请选择模板')
      }
      const elink = document.createElement('a')
      elink.target = '_blank'
      elink.style.display = 'none'
      let token = window.sessionStorage.getItem('authorization')
      if (token) {
        token = token.replace('"', '')
      }
      elink.href = '/api/contract/contract/previewTemplate?token=' + token + '&name=' + this.scontractmodel
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)
    }
  }
}
</script>

<style scoped>
</style>
